THREE.Line3.prototype.collinear = function (line) {
  //Otherwise equal if delta is same and they pass through (at least) one shared point

  var thisDeltaNormalized = this.delta(new THREE.Vector3()).normalize()
  var lineDeltaNormalized = line.delta(new THREE.Vector3()).normalize()

  //line directions are the same (or directly opposite)
  if (thisDeltaNormalized.equals(lineDeltaNormalized) || thisDeltaNormalized.equals(lineDeltaNormalized.negate())) {
    var closestPoint = this.closestPointToPoint(line.start, false, new THREE.Vector3())

    //distance from one line to another point on the other is zero
    if (closestPoint.distanceToSquared(line.start) < 0.00001) {
      return true
    }
  }
  return false
}

THREE.Plane.prototype.intersectPlane = function (plane) {
  //https://stackoverflow.com/questions/16025620/finding-the-line-along-the-the-intersection-of-two-planes

  /*
	Algorithm taken from http://geomalgorithms.com/a05-_intersect-1.html. See the
	section 'Intersection of 2 Planes' and specifically the subsection
	(A) Direct Linear Equation
	*/
  function intersectPlanes(p1, p2) {
    // the cross product gives us the direction of the line at the intersection
    // of the two planes, and gives us an easy way to check if the two planes
    // are parallel - the cross product will have zero magnitude
    var direction = new THREE.Vector3().crossVectors(p1.normal, p2.normal)
    var magnitude = direction.distanceTo(new THREE.Vector3(0, 0, 0))
    if (magnitude === 0) {
      return null
    }

    // now find a point on the intersection. We use the 'Direct Linear Equation'
    // method described in the linked page, and we choose which coordinate
    // to set as zero by seeing which has the largest absolute value in the
    // directional vector

    var X = Math.abs(direction.x)
    var Y = Math.abs(direction.y)
    var Z = Math.abs(direction.z)

    var point

    if (Z >= X && Z >= Y) {
      point = solveIntersectingPoint('z', 'x', 'y', p1, p2)
    } else if (Y >= Z && Y >= X) {
      point = solveIntersectingPoint('y', 'z', 'x', p1, p2)
    } else {
      point = solveIntersectingPoint('x', 'y', 'z', p1, p2)
    }

    return [point, direction]
  }

  /*
	This method helps finding a point on the intersection between two planes.
	Depending on the orientation of the planes, the problem could solve for the
	zero point on either the x, y or z axis
	*/
  function solveIntersectingPoint(zeroCoord, A, B, p1, p2) {
    var a1 = p1.normal[A]
    var b1 = p1.normal[B]
    var d1 = p1.constant

    var a2 = p2.normal[A]
    var b2 = p2.normal[B]
    var d2 = p2.constant

    var A0 = (b2 * d1 - b1 * d2) / (a1 * b2 - a2 * b1)
    var B0 = (a1 * d2 - a2 * d1) / (a1 * b2 - a2 * b1)

    var point = new THREE.Vector3()
    point[zeroCoord] = 0
    point[A] = A0
    point[B] = B0

    return point
  }

  var pointAndDirection = intersectPlanes(this, plane)

  if (pointAndDirection) {
    var point = pointAndDirection[0]
    var direction = pointAndDirection[1]
    return new THREE.Line3(point, point.clone().add(direction))
  } else {
    return null
  }
}

THREE.Plane.intersectLine = (function () {
  var v1 = new THREE.Vector3()

  return function intersectLine(line, optionalTarget, clampToLine) {
    var result = optionalTarget || new THREE.Vector3()

    var direction = line.delta(v1)

    var denominator = this.normal.dot(direction)

    if (denominator === 0) {
      // line is coplanar, return origin
      if (this.distanceToPoint(line.start) === 0) {
        return result.copy(line.start)
      }

      // Unsure if this is the correct method to handle this case.
      return undefined
    }

    var t = -(line.start.dot(this.normal) + this.constant) / denominator

    if (clampToLine && (t < 0 || t > 1)) {
      return undefined
    }

    return result.copy(direction).multiplyScalar(t).add(line.start)
  }
})()

//ThreeJS: Disable toJSON for textures due to errors with ShadowMaterial
THREE.Texture.prototype.toJSON = function (meta) {
  return []
}

//ThreeJS: Disable toJSON for materials due to errors with ShadowMaterial
THREE.Material.prototype.toJSON = function (meta) {
  return []
}

//ThreeJS: Disable toJSON for ShaderMaterial due to errors
THREE.ShaderMaterial.prototype.toJSON = function (meta) {
  return []
}

// MonkeyPatch Box3 to allow passing filterFunction to skip objects
// from boundingBox.setFromObject/expandByObject
//
// MonkeyPatch Box3 to add support for Bounding Boxes in a provided space, rather than world space
// From https://github.com/mrdoob/three.js/issues/11967#issuecomment-323769222
THREE.Box3.prototype.setFromObject = function (object, filterFunction, transform) {
  this.makeEmpty()

  return this.expandByObject(object, filterFunction, transform)
}

THREE.Box3.prototype.expandByObject = (function () {
  var v1 = new THREE.Vector3()

  return function expandByObject(object, filterFunction, transform) {
    var scope = this

    object.updateMatrixWorld(true)

    object.traverse(function (node) {
      if (filterFunction && filterFunction(node) == false) {
        //Object failed the filterFunction, skip it
        return
      }

      var i, l

      var geometry = node.geometry

      if (geometry !== undefined) {
        if (geometry.isGeometry) {
          var vertices = geometry.vertices

          for (i = 0, l = vertices.length; i < l; i++) {
            v1.copy(vertices[i])
            v1.applyMatrix4(node.matrixWorld)

            if (transform !== undefined) v1.applyMatrix4(transform)

            scope.expandByPoint(v1)
          }
        } else if (geometry.isBufferGeometry) {
          var attribute = geometry.attributes.position

          if (attribute !== undefined) {
            for (i = 0, l = attribute.count; i < l; i++) {
              v1.fromBufferAttribute(attribute, i).applyMatrix4(node.matrixWorld)

              if (transform !== undefined) v1.applyMatrix4(transform)

              scope.expandByPoint(v1)
            }
          }
        }
      }
    })

    return this
  }
})()

THREE.Box3.prototype.setFromArrayWithTransform = function (flatCoordinatesArray, transform) {
  var v1 = new THREE.Vector3()

  var transformInverted = new THREE.Matrix4().getInverse(transform)

  for (var i = 0; i < flatCoordinatesArray.length; i += 3) {
    v1.x = flatCoordinatesArray[i]
    v1.y = flatCoordinatesArray[i + 1]
    v1.z = flatCoordinatesArray[i + 2]
    v1.applyMatrix4(transformInverted)
    this.expandByPoint(v1)
  }
  return this
}

var LoadTextureWithHeadersResponseCache = {}

var LoadTextureWithHeadersResponseCacheMaximumEntries = 1

// Use instead of TextureLoader because we need to pass authentication headers
window.LoadTextureWithHeaders = function (url, onLoad, onProgress, onError, requestHeader, mimeType, texture) {
  if (!texture) {
    texture = new THREE.Texture()
  }
  texture.minFilter = THREE.LinearFilter

  var processBlobUrl = function (blobUrl) {
    var image = new Image()
    image.onload = function () {
      if (onLoad) {
        onLoad(image, texture)
      }
      image.loaded = true
    }.bind(this)
    image.src = blobUrl
    texture.image = image
    texture.needsUpdate = true
  }

  // if (Object.values(LoadTextureWithHeadersResponseCache).length) {
  //   LoadTextureWithHeadersResponseCache[url] = Object.values(LoadTextureWithHeadersResponseCache)[0]
  // }

  var urlCleaned = SceneHelper.cleanUrlForTerrainEndpoint(url)
  var urlRefresh = SceneHelper.refreshUrl(url)

  var forceOnProgressComplete = function () {
    if (onProgress) {
      // Use to force progress completion when we hit cache
      // Call onProgress to ensure it is complete
      onProgress({ loaded: 100000, total: 100000 })
    }
  }

  if (LoadTextureWithHeadersResponseCache[url]) {
    if (window.studioDebug) {
      console.log('******** use cached ' + url)
    }
    processBlobUrl(LoadTextureWithHeadersResponseCache[url])
    forceOnProgressComplete()
  } else if (LoadTextureWithHeadersResponseCache[urlCleaned]) {
    if (window.studioDebug) {
      console.log('******** use cached ' + urlCleaned)
    }
    processBlobUrl(LoadTextureWithHeadersResponseCache[urlCleaned])
    forceOnProgressComplete()
  } else if (LoadTextureWithHeadersResponseCache[urlRefresh]) {
    if (window.studioDebug) {
      console.log('******** use cached ' + urlRefresh)
    }
    processBlobUrl(LoadTextureWithHeadersResponseCache[urlRefresh])
    forceOnProgressComplete()
  } else {
    if (window.studioDebug) {
      console.log('******** not cached ' + url)
    }
    var loader = new THREE.FileLoader()
    loader.crossOrigin = 'anonymous'
    loader.mimeType = mimeType || 'image/jpeg'
    loader.responseType = 'blob'
    if (requestHeader) {
      loader.requestHeader = requestHeader
    }
    loader.load(
      url,
      function (response) {
        const blobUrl = URL.createObjectURL(response)

        processBlobUrl(blobUrl)

        // Only allow one cached texture at a time, clear old cached items
        var cachedItemKeys = Object.keys(LoadTextureWithHeadersResponseCache)
        if (cachedItemKeys.length > LoadTextureWithHeadersResponseCacheMaximumEntries) {
          var cachedItemsToRemove = cachedItemKeys.length - LoadTextureWithHeadersResponseCacheMaximumEntries
          for (var i = 0; i < cachedItemsToRemove.length; i++) {
            var keyToDelete = Object.keys(LoadTextureWithHeadersResponseCache)[0]
            var oldBlobUrl = LoadTextureWithHeadersResponseCache[keyToDelete]
            URL.revokeObjectURL(oldBlobUrl)
            console.log('URL.revokeObjectURL', oldBlobUrl)
            delete LoadTextureWithHeadersResponseCache[keyToDelete]
          }
        }
        LoadTextureWithHeadersResponseCache[url] = blobUrl
      },
      onProgress,
      onError
    )
  }
  return texture
}
