/**
 * @author Bjorn Sandvik / http://thematicmapping.org/
 */

var TerrainLoaderCache = {}

THREE.TerrainLoader = function (manager) {
  this.manager = manager !== undefined ? manager : THREE.DefaultLoadingManager
}

THREE.TerrainLoader.checkCache = function (url) {
  return Boolean(TerrainLoaderCache[url])
}

THREE.TerrainLoader.prototype = {
  constructor: THREE.TerrainLoader,

  preload: function (url, onLoad, onError, headers) {
    var request = new XMLHttpRequest()

    request.addEventListener(
      'load',
      function (event) {
        // store the raw response in the cache so it can be used in future
        TerrainLoaderCache[url] = event.target.response
        console.log('TerrainLoaderCache saved', url, event.target.response)

        if (onLoad) {
          onLoad(event.target.response)
        }
      },
      false
    )

    request.addEventListener(
      'error',
      function (event) {
        console.warn('TerrainLoader error', event)

        if (onError) {
          onError(event)
        }
      },
      false
    )

    if (this.crossOrigin !== undefined) request.crossOrigin = this.crossOrigin

    request.open('GET', url, true)

    request.responseType = 'arraybuffer'

    if (headers) {
      Object.keys(headers).forEach((key) => {
        request.setRequestHeader(key, headers[key])
      })
    }

    request.send(null)
  },

  load: function (url, onLoad, onProgress, onError, headers) {
    var scope = this

    if (TerrainLoaderCache[url] && onLoad) {
      console.log('Cache hit for TerrainLoader')
      scope.manager.itemStart(url)
      onLoad(TerrainLoaderCache[url])
      scope.manager.itemEnd(url)

      // Force onProgress because this will no update with network progress events
      if (onProgress) {
        onProgress({ loaded: 100000, total: 100000 })
      }

      return
    }

    var request = new XMLHttpRequest()

    if (onLoad) {
      request.addEventListener(
        'load',
        function (event) {
          // Load .bin format
          // onLoad(new Uint16Array(event.target.response))

          // Load .tif format
          onLoad(event.target.response)
          scope.manager.itemEnd(url)
        },
        false
      )
    }

    if (onProgress) {
      request.addEventListener(
        'progress',
        function (event) {
          onProgress(event)
        },
        false
      )
    }

    if (onError) {
      request.addEventListener(
        'error',
        function (event) {
          onError(event)
        },
        false
      )
    }

    if (this.crossOrigin !== undefined) request.crossOrigin = this.crossOrigin

    request.open('GET', url, true)

    request.responseType = 'arraybuffer'

    if (headers) {
      Object.keys(headers).forEach((key) => {
        request.setRequestHeader(key, headers[key])
      })
    }

    request.send(null)

    scope.manager.itemStart(url)
  },

  setCrossOrigin: function (value) {
    this.crossOrigin = value
  },
}
